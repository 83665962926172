import styled from "styled-components";

export const Row = styled.div`
display: flex;
flex-flow: row wrap;
width: 100%;
margin-bottom: 20px;
justify-content: space-between;
`
export const HeaderContainer = styled.div`
max-width: 1280px;
margin-left: auto;
margin-right: auto;
padding-top: 20px;
padding-bottom: 20px;
`

export const HeaderWrapper = styled.nav`
background: #ECECEC;
min-height: 100px;
padding-top: 20px;
padding-bottom: 20px;
`

export const StyledImg = styled.img`
background: #ECECEC;
`

export const StyledLink = styled.a`
font-family: Roboto Condensed;
font-style: normal;
font-weight: 300;
font-size: 18px;
line-height: 21px;
text-align: justify;
letter-spacing: 0.05em;
padding-left: 5px;
padding-right: 5px;
color: #2B2E4A;
cursor: pointer;
`

export const NavRow = styled.div`
display: flex;
flex-flow: row wrap;
justify-content: space-around;
min-width: 350px;
align-items: center;
`