import PropTypes from "prop-types";
import React from "react"
import { Link } from "gatsby";
import { fadeIn } from "../theme/animations"
import styled from "styled-components";
import { Row, NavRow, HeaderWrapper, StyledImg } from "../theme/header.css";
import { Container } from "../theme/home.css";
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const StyledLink = styled.span`
font-family: Roboto Condensed;
font-style: normal;
font-weight: 300;
font-size: 18px;
line-height: 21px;
text-align: justify;
letter-spacing: 0.05em;
padding-left: 5px;
padding-right: 5px;
color: #2B2E4A;
cursor: pointer;
animation: ${fadeIn} 2s ease-in 0s 1 normal none;
&:visited {
  color: #2B2E4A;
  text-decoration: none;
}

&:link {
  color: #2B2E4A;
  text-decoration: none;
}

&:hover {
  color: #2B2E4A;
  text-decoration: none;
}

&:active {
  color: #2B2E4A;
  text-decoration: none;
}
`

const Header = ({ siteTitle }) => (
  <HeaderWrapper>
  <Container>
      <Row>
          <AniLink cover direction="right" bg="#e84545" to="/">
          <StyledImg  src={'https://res.cloudinary.com/dfqkwnen0/image/upload/v1596235321/Hassan_Logo_1_oyykks.png'} />
          </AniLink>
          <NavRow>
          <Link  to="/#about">
          <StyledLink>
          About Me
          </StyledLink>
          </Link>
          <Link  to="/#experience" >
          <StyledLink>Experience</StyledLink>
          </Link>
          <Link to="#contact">
          <StyledLink>Contact</StyledLink>
          </Link>
          </NavRow>
      </Row>
  </Container>
  </HeaderWrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
