import React from "react"
import styled from "styled-components";
import * as Styles from "../theme/home.css";
import { faGithub, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SubHeader = styled.span`
display: flex;
font-family: Roboto Condensed;
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 29px;
text-align: center;
letter-spacing: 0.05em;
color: #30475E;
align-items: center;
padding-bottom: 20px;
width: 100%;
white-space: nowrap;
&:after {
    content: "";
    display: block;
    width: 50%;
    border-bottom: 2px solid #E84545;
    position: relative;
    margin-left: 40px;
    align-self: end;
    margin-bottom: auto;
    margin-top: auto;
}
&:before {
    content: "";
    display: block;
    width: 50%;
    border-bottom: 2px solid #E84545;
    position: relative;
    margin-right: 40px;
    align-self: end;
    margin-bottom: auto;
    margin-top: auto;
}
`
const FootNote = styled.div`
font-family: Roboto Condensed;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 26px;
text-align: center;
letter-spacing: 0.05em;
text-transform: capitalize;
color: #E84545;
`

const FooterIconBar = styled.div`
display:flex;
flew-flow: row wrap;
justify-content: center;
padding-top: 20px;
padding-bottom: 20px;
`

const IconWrapper = styled.a`
font-size: 30px;
padding: 10px 15px;
border: 3px solid #e84545;
border-radius: 50%;
background: #e84545;
color: #30475e;
margin-left: 10px;
margin-right: 10px;
cursor: pointer;
transition: all 0.3s ease-in;
&:hover {
    background: ${props => props.background};
    border: 3px solid ${props => props.background};
    color: ${props => props.color};
  }
`

const Footer = () => {
    return (
        <Styles.Footer id="contact">
            <Styles.Container>
            <SubHeader>CONTACT</SubHeader>
            <FooterIconBar>
            <IconWrapper rel="noreferrer" background={'#6e5494'} color={'#fafafa'} target="_blank" href="https://github.com/HassanBello">
                <FontAwesomeIcon icon={faGithub} />
            </IconWrapper>
            {/* <IconWrapper rel="noreferrer" background={'#1da1f2'} color={'#f5f8fa'} target="_blank" href="https://twitter.com/R4G3KN1GHT">
                <FontAwesomeIcon icon={faTwitter} />
            </IconWrapper> */}
            <IconWrapper rel="noreferrer" background={'#f5f8fa'} color={'#4285f4'} target="_blank" href="https://www.linkedin.com/in/hassan-bello-7261aa123/">
                <FontAwesomeIcon icon={faLinkedin} />
            </IconWrapper>
            <IconWrapper rel="noreferrer" background={'#f5f8fa'} color={'#4285f4'} target="_blank" href="mailto:modupe.bello98@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} />
            </IconWrapper>
            </FooterIconBar>
            <FootNote>Built with Gatsbyjs by Me :)</FootNote>
            </Styles.Container>
        </Styles.Footer>
    )
}

export default Footer;