import styled from "styled-components";
import media from './media';
import { fadeRight, fadeIn } from "./animations"

export const Row = styled.div`
display: flex;
flex-flow: row wrap;
width: 100%;
margin-bottom: 20px;
justify-content: space-between;
align-items: center;
`
export const Column = styled.div`
display: flex;
flex-flow: column wrap;
`

export const Container = styled.div`
max-width: 1280px;
padding-right: 100px;
padding-left: 100px;
${media.tablet`padding-right: 15px;`};
${media.tablet`padding-left: 15px;`};
margin-right: auto;
margin-left: auto;
`

export const BannerWrapper = styled.div`
margin-right: auto;
margin-left:  auto;
padding-top: 80px;
padding-bottom: 80px;
${media.tablet`padding-bottom: 40px;`};
${media.tablet`padding-top: 40px;`};
`
export const Banner = styled.div`
`

export const IntroHeader = styled.span`
font-family: Roboto Condensed;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 35px;
text-transform: uppercase;
color: #2B2E4A;
${media.tablet`font-size: 20px;`};
${media.phablet`font-size: 1.15rem;`};
padding-bottom: 20px;
${media.tablet`padding-bottom: 0px;`};
`

export const SubHeader = styled.span`
display: flex;
font-family: Roboto Condensed;
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 29px;
text-align: justify;
letter-spacing: 0.05em;
color: #30475E;
align-items: center;
padding-bottom: 20px;
width: 100%;
white-space: nowrap;
animation: ${fadeRight} 2s linear 0s 1 normal none;
&:after {
    content: "";
    display: block;
    width: 25%;
    border-bottom: 2px solid #E84545;
    position: relative;
    margin-left: 20px;
    align-self: end;
    margin-bottom: auto;
    margin-top: auto;
}
`
export const StyledSection = styled.section`
padding-top: 50px;
`

export const StyledHr = styled.hr`
  padding-top: 5px;
  padding-bottom: 2px;
  height: 0;
  border-top: 2px solid #E84545;
  width: 100%;
`;

export const StyledParagraph = styled.p`
font-family: Roboto Condensed;
font-style: normal;
font-weight: normal;
font-size: 19px;
line-height: 22px;
text-align: justify;
letter-spacing: 0.05em;
color: #2B2E4A;
padding-bottom: 15px;
margin: auto;
width: 100%;
${media.tablet`font-size: 1rem;`};
`
export const StyledImg = styled.img`
background: #ECECEC;
width: 100%;
`
export const PassPortConatiner = styled.div`
max-width: 310px;
max-height: 313px;
align-self: center;
`

export const TabWrapper = styled.div`
 display: flex;
 ${media.phablet`flex-direction: column;`};
 
`
export const TabCol1 = styled.div`
border-right: 1px solid #E84545;
${media.tablet`border-right: 0px;`};
${media.tablet`border-bottom: 1px solid #E84545;`};
display: flex;
flex-flow: column wrap;
min-height: 256px;
${media.tablet`min-height: 0;`};
`
export const TabCol2 = styled.div`
display: flex;
flex-flow: column wrap;
padding: 10px;
`

export const TabHeader = styled.div`
font-family: Roboto Condensed;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.05em;
color: #2B2E4A;
background: ${props => {
  switch (props.active) {
    case true:
      return "rgba(232, 69, 69, 0.1)";
    default:
      return "inherit";
  };
}};
border-right: ${props => {
  switch (props.active) {
    case true:
      return "1px solid #E84545";
    default:
      return "none";
  };
}};
${media.tablet`border-right: 0px;`};
${media.tablet`border-bottom: 2px solid #E84545; text-align: right;`};
width: 100%;
padding: 10px;
text-align: left;
max-height: 40px;
cursor: pointer;
transition: 0.3s ease;
`

export const PanelWrapper = styled.div`
display: flex;
flex-direction: column;
animation: ${fadeIn} 0.4s ease-in 0s 1 normal none;
`

export const Footer = styled.div`
padding-top: 50px;
padding-bottom: 10px;
`